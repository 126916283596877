import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useAxiosClient from "../../hooks/useAxiosClient";
import { TRootState } from "../../store";
import {TExcessPaymentReport } from "../../types";

const useExcessPaymentReport = () => {
  const { handleGetCall } = useAxiosClient();

const [isLoading, setIsLoading] = useState(true);

  const { adminId } = useSelector((state: TRootState) => ({
    adminId: state.auth.adminId,
  }));

  const [allExcessPaymentReport, setAllExcessPaymentReport] = useState<
    TExcessPaymentReport[]
  >([]);
  useEffect(() => {
    handleGetCall<TExcessPaymentReport[]>(
      `reports/mandi-recon/open-trades/Recon/adminId/${adminId}`
    ).then(({ data }) => {
      if (data) {
        setAllExcessPaymentReport(data);
     setIsLoading(false);
      } else {
        setAllExcessPaymentReport([]);
     setIsLoading(false);
      }
    });
  }, []);

  return {
    setAllExcessPaymentReport,
    allExcessPaymentReport,
     isLoading
  };
};
export default useExcessPaymentReport;

