import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAllDemands from "../../hooks/useAllDemandDetails";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import NodataAvailable from "../../assets/images/NodataAvailable.svg";
import useReportsDetails from "./useReportsData";
import {
  getCurrencyNumberWithOutSymbol,
  getCurrencyNumberWithSymbol,
} from "../../helpers/get-currency-number";
import { FaAngleDown, FaSearch } from "react-icons/fa";
import { Button, Input, Popover } from "../../components/ui";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import excel from "../../assets/images/excel.svg";
import Modal from "../../components/ui/modal";
import ModalHeader from "../../components/ui/modal/modal-header";
import Calender from "../../components/commons/calender";
import { Checkbox } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { TRootState } from "../../store";
import {
  setSelectedREportStatus,
  setSelectedREportStatusFilter,
  setSelectedStatus,
} from "../../reducers/tradeSlice";
const DebitCreditReports = () => {
  const statusList = [
    {
      label: "pending",
      value: "PENDING",
    },
    {
      label: "reverted",
      value: "REVERTED",
    },
    {
      label: "reversed",
      value: "REVERSED",
    },
    {
      label: "cancelled",
      value: "CANCELLED",
    },
    {
      label: "failed",
      value: "FAILED",
    },
    {
      label: "rejected",
      value: "REJECTED",
    },
    {
      label: "accepted",
      value: "ACCEPTED",
    },
    {
      label: "initiated",
      value: "INITIATED",
    },
    {
      label: "cancel",
      value: "CANCEL",
    },
  ];

  const dispatch = useDispatch();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [isCalender, setIsCalender] = useState(false); //show or hide the calender
  const [dateChange, setDateChange] = useState(false); //manage state when the caender date is changed
  const [calenderDate, setCalenderDate] = useState(
    //calender date for storing the data which we are entering fromDate toDate in calender
    {
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
    }
  );
  const [openStatusFilter, setOpenStatusFilter] = useState(false);

  const { allReportsData, searchText, setSearchText } = useReportsDetails({
    fromDate: dateChange ? calenderDate.fromDate : "",
    toDate: dateChange ? calenderDate.toDate : "",
  });
  const { selectedReportStatus, selectedReportStatusFilter } = useSelector(
    (state: TRootState) => ({
      selectedReportStatus: state.trades.selectedReportStatus,
      selectedReportStatusFilter: state.trades.selectedReportStatusFilter,
    })
  );
  const [filteredTraders, setFilteredTraders] = useState<any[]>([]);
  const [open, setOpen] = useState(false);

  const data = filteredTraders.map((item, i) => ({
    tradeId: item.tradeId,
    PayoutId: item.payoutId,
    Date: item.txDate,
    creditDebit: item.creditDebit,
    pmtType: item.pmtType,
    pmtMode: item.pmtMode,
    UTR: item.utr,
    Description: item.desc,
    Amount: item.amount,
  }));
  const handleExport = () => {
    // Define the static table headers and data
    const headers = [
      "Trade Id",
      "Payout Id",
      "Tx.Date",
      "Debit/Credit",
      "Payment Type",
      "Payment Mode",
      "UTR",
      "Description",
      "Amount",
    ];
    // Prepare the data for export
    const exportData = [
      headers, // Header row
      ...data.map((row) => [
        row.tradeId,
        row.PayoutId,
        row.Date,
        row.creditDebit,
        row.pmtType,
        row.pmtMode,
        row.UTR,
        row.Description,
        row.Amount,
      ]), // Data rows
    ];
    if (data.length > 0) {
      const worksheet = XLSX.utils.aoa_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Reports");
      // Export the workbook to an Excel file
      XLSX.writeFile(
        workbook,
        `Debits & Credits Report - ${
          dateChange && calenderDate.fromDate
            ? moment(calenderDate.fromDate).format("DD-MMM-YYYY")
            : moment(calenderDate.fromDate).format("MMM")
        } - ${
          dateChange && calenderDate.toDate
            ? moment(calenderDate.toDate).format("DD-MMM-YYYY")
            : moment(calenderDate.toDate).format("YYYY")
        }.xlsx`
      );
    } else {
      toast.error("No data available to export excel.");
    }
  };
  const [localSelectedStatus, setLocalSelectedStatus] = useState<string[]>([]);

  useEffect(() => {
    if (localSelectedStatus.length > 0) {
      const updatedFilterdStatus = localSelectedStatus.filter((item) =>
        selectedReportStatus.includes(item)
      );
      setLocalSelectedStatus(updatedFilterdStatus);
    }
  }, [open]);
  useEffect(() => {
    // Initialize localSelectedStatus with selectedStatus from Redux on component mount or update
    setLocalSelectedStatus(selectedReportStatus);
  }, [selectedReportStatus]);

  const filterTraders = () => {
    let filtered = allReportsData;
    const trimmedSearchText = searchText.trim().toLowerCase();
    // Apply search filter
    if (searchText.length > 0) {
      filtered = filtered.filter(
        (option) =>
          (option.creditDebit &&
            option.creditDebit.toLowerCase().includes(trimmedSearchText)) ||
          (option.tradeId &&
            option.tradeId
              .toString()
              .toLowerCase()
              .includes(trimmedSearchText)) ||
          (option.payoutId &&
            option.payoutId
              .toString()
              .toLowerCase()
              .includes(trimmedSearchText))
      );
    }

    // Apply status filter from Redux selectedStatus
    if (selectedReportStatus.length > 0) {
      filtered = filtered.filter((option) =>
        selectedReportStatus.includes(option.creditDebit)
      );
    }
    if (selectedReportStatusFilter.length > 0) {
      filtered = filtered.filter((option) =>
        selectedReportStatusFilter.includes(option.status)
      );
    }

    setFilteredTraders(filtered);
    setOpen(false);
    setOpenStatusFilter(false);
  };

  // Call filterTraders whenever searchText or selectedStatus in Redux changes
  useEffect(() => {
    filterTraders();
  }, [
    searchText,
    selectedReportStatus,
    allReportsData,
    selectedReportStatusFilter,
  ]);

  // Handle checkbox changes for localSelectedStatus without dispatching to Redux
  const handleCheckboxChange = (status: string) => {
    setLocalSelectedStatus((prev) =>
      prev.includes(status)
        ? prev.filter((s) => s !== status)
        : [...prev, status]
    );
  };

  const handleApply = () => {
    dispatch(setSelectedREportStatus(localSelectedStatus)); // Update Redux state with local selected statuses
    setOpen(false);
  };

  const handleClearAll = () => {
    setSearchText(""); // Clear search text if desired
    setFilteredTraders([]);
    setLocalSelectedStatus([]); // Clear local selected statuses
    dispatch(setSelectedREportStatus([])); // Clear Redux state
    setOpen(false);
  };
  const traderStatus = [
    { status: "INWARD", id: 1 },
    { status: "OUTWARD", id: 2 },
  ];
  const [selectStatus, setSelectStatus] = useState<string[]>([]);

  // Handle checkbox changes for localSelectedStatus without dispatching to Redux
  const handleStatusCheckbox = (status: string) => {
    setSelectStatus((prev) =>
      prev.includes(status)
        ? prev.filter((s) => s !== status)
        : [...prev, status]
    );
  };

  const ApplyStatusFilter = () => {
    dispatch(setSelectedREportStatusFilter(selectStatus));
    setOpenStatusFilter(false);
  };

  const ClearStatusFilter = () => {
    setSelectStatus([]);
    dispatch(setSelectedREportStatusFilter([]));
    setOpenStatusFilter(false);
  };

  useEffect(() => {
    setSelectStatus([]);
    dispatch(setSelectedREportStatusFilter([]));
  }, []);

  const panelRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        panelRef.current &&
        !panelRef.current.contains(event.target as Node)
      ) {
        setOpenStatusFilter(false);
      }
    };
    if (openStatusFilter) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openStatusFilter]);
  const tableStyle: React.CSSProperties = {
    width: "100%",
    borderCollapse: "collapse",
    borderRadius: "10px",
    overflow: "hidden",
    border: "1px solid #ccc",
  };
  return (
    <>
      <div className="">
        <div className="flex justify-between pb-3">
          <div
            className="relative border border-gray-300 rounded focus-within:border-green-500 focus-within:shadow-lg"
            style={{ width: "30%" }}
          >
            <FaSearch
              className="absolute color-primary"
              size={15}
              style={{ top: "14px", left: "6px" }}
            />
            <Input
              type="text"
              palceholder="Search by Trade Id / Date / Payout Id"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              inputStyle={{ textIndent: "22px", padding: "8px 5px" }}
            />
          </div>
          <div
            className="flex items-center gap-2 justify-end"
            style={{ width: "50%" }}
          >
            <div className=" flex gap-2 mr-2 md:mr-0"></div>
            <div
              onClick={() => {
                handleExport();
              }}
              className="text-sm cursor-pointer flex items-center justify-center br-10 gap-2 bg-white"
              style={{
                padding: "8px 5px",
              }}
            >
              <img
                src={excel}
                alt="img"
                className=""
                style={{ width: "25px" }}
              />{" "}
              <span>Export to Excel</span>
            </div>
          </div>
        </div>
        <div
          className="overflow-auto"
          style={{
            height: "calc(100vh - 10rem)",
          }}
        >
          <table className="w-full border-collapse" style={tableStyle}>
            <thead className="bg-light-primary text-left sticky top-0 z-10">
              <tr className="text-base font-semibold">
                <th
                  className="px-2 md:px-4 py-2 border text-center w-[1%]"
                  style={{
                    backgroundColor: "#d7f3dd",
                  }}
                >
                  #
                </th>
                <th
                  className="px-2 md:px-4 py-2 border"
                  style={{
                    backgroundColor: "#d7f3dd",
                    minWidth: "100px",
                  }}
                >
                  Trade Id
                </th>
                <th
                  className="px-2 md:px-4 py-2 border"
                  style={{ minWidth: "100px" }}
                >
                  Payout Id
                </th>
                <th
                  className="px-2 md:px-4 py-2  border"
                  style={{ minWidth: "120px" }}
                >
                  <div className="flex items-center">
                    {" "}
                    <span
                      className="color-blue flex ml-1 cursor-pointer"
                      onClick={() => setIsCalender(true)}
                    >
                      Tx.Date
                      <FaAngleDown className="ml-1 mt-[5px]" />
                    </span>
                  </div>
                </th>
                <th
                  className="px-2 md:px-4 py-2 border"
                  style={{ minWidth: "200px" }}
                >
                  Receipt Uploaded Date
                </th>

                <th
                  className="px-2 md:px-4 py-2  border"
                  style={{ minWidth: "120px" }}
                >
                  <div className="flex items-center">
                    {" "}
                    <span
                      className="color-blue flex ml-1 cursor-pointer"
                      onClick={() => setOpen(true)}
                    >
                      Debit/Credit
                      <FaAngleDown className="ml-1 mt-[5px]" />
                    </span>
                  </div>
                  {open && (
                    <div className="mr-0 md:mr-12">
                      <Popover
                        open={open}
                        onClose={() => {
                          setOpen(false);
                        }}
                        className="w-[230px] h-auto"
                        style={{
                          marginLeft:
                            window.innerWidth >= 768 ? "7.2rem" : undefined,
                          marginRight:
                            window.innerWidth < 768 ? "1rem" : undefined,
                        }}
                      >
                        <div>
                          {traderStatus.map((status) => (
                            <div
                              className="p-1 cursor-pointer"
                              onClick={() =>
                                handleCheckboxChange(status.status)
                              }
                            >
                              <Checkbox
                                {...label}
                                checked={localSelectedStatus.includes(
                                  status.status
                                )}
                                // onChange={() => handleCheckboxChange(status.status)}
                              />{" "}
                              {status.status}
                            </div>
                          ))}

                          <div
                            className="flex p-3 gap-2"
                            style={{ width: "220px" }}
                          >
                            <button
                              className=" align-items-center rounded-md h-[40px]"
                              style={{
                                backgroundColor: "#d7f3dd",
                                width: "100px",
                              }}
                              onClick={() => {
                                handleClearAll();
                              }}
                            >
                              <span className="color-primary"> Clear All</span>
                            </button>
                            <button
                              className="bg-primary align-items-center rounded-md h-[40px]"
                              style={{
                                backgroundColor: "white",
                                width: "100px",
                              }}
                              onClick={() => {
                                handleApply();
                                filterTraders();
                              }}
                            >
                              <span className="text-white">Apply</span>
                            </button>
                          </div>
                        </div>
                      </Popover>
                    </div>
                  )}
                </th>
                <th className="px-2 md:px-4 py-2 border w-[10%]">
                  Payment Type
                </th>
                <th
                  className="px-2 md:px-4 py-2 border"
                  style={{ minWidth: "140px" }}
                >
                  Payment Mode
                </th>
                <th
                  className="px-2 md:px-4 py-2 border"
                  style={{ minWidth: "250px" }}
                >
                  Description
                </th>
                <th
                  className="px-2 md:px-4 py-2 border"
                  style={{ minWidth: "180px" }}
                >
                  UTR
                </th>
                <th
                  className="px-2 md:px-4 py-2 border"
                  style={{ minWidth: "150px" }}
                >
                  Amount (₹)
                </th>
                <th
                  className="px-2 md:px-4 py-2 border color-blue"
                  onClick={() => {
                    setOpenStatusFilter(true);
                  }}
                  style={{ minWidth: "150px" }}
                >
                  <div className="flex items-center">
                    {" "}
                    <span className="color-blue flex ml-1 cursor-pointer">
                      Status <FaAngleDown className="ml-1 mt-[5px]" />
                    </span>
                  </div>

                  <div
                    className=""
                    ref={panelRef}
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {openStatusFilter && (
                      <div className="mr-0 md:mr-12">
                        <Popover
                          open={openStatusFilter}
                          onClose={() => {
                            setOpenStatusFilter(false);
                          }}
                          className="w-[230px] h-auto"
                          style={{
                            marginLeft:
                              window.innerWidth >= 768 ? "1rem" : undefined,
                            marginRight:
                              window.innerWidth < 768 ? "1rem" : undefined,
                          }}
                        >
                          <div>
                            {statusList.map((status) => (
                              <div
                                className="p-1 cursor-pointer"
                                onClick={() =>
                                  handleStatusCheckbox(status.value)
                                }
                              >
                                <Checkbox
                                  {...label}
                                  checked={selectStatus.includes(status.value)}
                                  // onChange={() => handleCheckboxChange(status.status)}
                                />{" "}
                                {status.value}
                              </div>
                            ))}

                            <div
                              className="flex p-3 gap-2"
                              style={{ width: "220px" }}
                            >
                              <button
                                className=" align-items-center rounded-md h-[40px]"
                                style={{
                                  backgroundColor: "#d7f3dd",
                                  width: "100px",
                                }}
                                onClick={() => {
                                  ClearStatusFilter();
                                }}
                              >
                                <span className="color-primary">
                                  {" "}
                                  Clear All
                                </span>
                              </button>
                              <button
                                className="bg-primary align-items-center rounded-md h-[40px]"
                                style={{
                                  backgroundColor: "white",
                                  width: "100px",
                                }}
                                onClick={() => {
                                  ApplyStatusFilter();
                                }}
                              >
                                <span className="text-white">Apply</span>
                              </button>
                            </div>
                          </div>
                        </Popover>
                      </div>
                    )}
                  </div>
                </th>
              </tr>
            </thead>

            {filteredTraders.length > 0 ? (
              <tbody>
                {(searchText !== ""
                  ? filteredTraders.filter((trade) => {
                      return (
                        trade.tradeId.toString().includes(searchText) ||
                        trade.txDate.toString().includes(searchText) ||
                        trade.payoutId.toString().includes(searchText)
                      );
                    })
                  : filteredTraders
                ).map((trade, index) => (
                  <tr
                    key={trade.payoutId}
                    className="cursor-pointer bg-white text-sm"
                  >
                    <td className="px-2 md:px-4 py-2 text-center border">
                      {index + 1}
                    </td>
                    <td className="px-2 md:px-4 py-2 border">
                      {trade.tradeId}
                    </td>
                    <td className="px-2 md:px-4 py-2 border">
                      {trade.payoutId}
                    </td>
                    <td className="px-2 md:px-4 py-2 border">
                      <div className="flex flex-col">
                        <span className="text-sm">
                          {trade.txDate
                            ? moment(trade.txDate).format("DD-MMM-YYYY")
                            : ""}
                        </span>
                      </div>
                    </td>
                    <td className="px-2 md:px-4 py-2 border">
                      {" "}
                      {trade.pmtDate
                        ? moment(trade.pmtDate).format("DD-MMM-YYYY")
                        : ""}
                    </td>
                    <td className="px-2 md:px-4 py-2 border">
                      {" "}
                      {trade.creditDebit}
                    </td>
                    <td className="px-2 md:px-4 py-2 border">
                      {" "}
                      {trade.pmtType}
                    </td>
                    <td className="px-2 md:px-4 py-2 border">
                      {" "}
                      {trade.pmtMode}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2 border "
                      style={{
                        wordBreak: "break-all",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {" "}
                      {trade.desc}
                    </td>
                    <td
                      className="px-2 md:px-4 py-2 border"
                      style={{
                        wordBreak: "break-all",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {" "}
                      {trade.utr}
                    </td>
                    <td className="px-2 md:px-4 py-2 border">
                      {" "}
                      {getCurrencyNumberWithOutSymbol(trade.amount)}
                    </td>
                    <td className="px-2 md:px-4 py-2 border">{trade.status}</td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={100} className="md:text-center p-0">
                    <div className="bg-white">
                      <img
                        src={NodataAvailable}
                        alt="No Data"
                        className="pl-12 md:pl-0 md:mx-auto py-5"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
      <div>
        <Modal
          open={isCalender}
          onClose={() => {
            setIsCalender(false);
          }}
          width={"30vw"}
        >
          <>
            <ModalHeader title={"Date Filter"} content={<></>} />
            <div className="py-3 px-2 md:px-6">
              <Calender
                mode={"range"}
                onChange={(data: { fromdate: string; todate: string }) => {
                  if (data.fromdate !== "" && data.todate !== "") {
                    setCalenderDate({
                      ...calenderDate,
                      fromDate: moment(data.fromdate, "YYYY-MMM-DD").format(
                        "YYYY-MM-DD"
                      ),
                      toDate: moment(data.todate, "YYYY-MMM-DD").format(
                        "YYYY-MM-DD"
                      ),
                    });
                  } else if (data.fromdate !== "" && data.todate === "") {
                    // single date selection
                    setCalenderDate({
                      ...calenderDate,
                      fromDate: moment(data.fromdate, "YYYY-MMM-DD").format(
                        "YYYY-MM-DD"
                      ),
                      toDate: moment(data.fromdate, "YYYY-MMM-DD").format(
                        "YYYY-MM-DD"
                      ),
                    });
                  }
                  setIsCalender(false); //idhi false cheyakapotey calender open loney untadi
                  setDateChange(true); // filtering data
                }}
                onClear={() => {
                  setCalenderDate({
                    ...calenderDate,
                    fromDate: moment().format("YYYY-MM-DD"),
                    toDate: moment().format("YYYY-MM-DD"),
                  });
                  setDateChange(false);
                  setIsCalender(false);
                }}
                initialDateRange={{
                  fromdate: calenderDate.fromDate,
                  todate: calenderDate.toDate,
                }}
                maxDate={moment().format("YYYY-MM-DD")}
                style={{}}
              />
            </div>
          </>
        </Modal>
      </div>
    </>
  );
};

export default DebitCreditReports;
