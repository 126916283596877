import useExcessPaymentReport from "./useExcessPaymentReport";
import moment from "moment";
import { getCurrencyNumberWithOutSymbol } from "../../helpers/get-currency-number";
import NodataAvailable from "../../assets/images/NodataAvailable.svg";
import { FaSearch } from "react-icons/fa";
import { Input } from "../../components/ui";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import excel from "../../assets/images/excel.svg";
import Loading from "../../assets/images/loading.gif";

const ExcessPaymentReport = () => {
  const { allExcessPaymentReport, isLoading } = useExcessPaymentReport();

  const [filteredTraders, setFilteredTraders] = useState<any[]>([]);

  const [searchText, setSearchText] = useState("");
  const filterTraders = () => {
    let filtered = allExcessPaymentReport;
    const trimmedSearchText = searchText.trim().toLowerCase();
    filtered = filtered?.filter(
      (option: any) =>
        option.tradeId &&
        option.tradeId.toString().toLowerCase().includes(trimmedSearchText)
    );
    setFilteredTraders(filtered);
  };

  const data = filteredTraders.map((item, i) => ({
    tradeId: item.tradeId,
    srcTradeName: item.srcTradeName,
    destTradeName: item.destTradeName,
    tradeType: item.tradeType,
    tradeStatus: item.tradeStatus,
    netPayable: item.netPayable,
    netReceivable: item.netReceivable,
    tradeRecon: item.tradeRecon,
    tradePmtsOutward: item?.tradePmts
      ?.filter((payment: any) => payment?.creditDebit === "OUTWARD")
      .map((payment: any) => ({
        payoutId: payment?.payoutId,
        paid: payment?.paid,
        pmtType: payment?.pmtType,
        pmtDate: payment?.pmtDate,
        pmtMode: payment?.pmtMode,
        utr: payment?.utr,
        desc: payment?.desc,
        amount: payment?.amount,
        status: payment?.status,
      })),
    srcRecon: item?.srcRecon,
    tradePmtsInward: item?.tradePmts
      ?.filter((payment: any) => payment?.creditDebit === "INWARD")
      .map((payment: any) => ({
        payoutId: payment?.payoutId,
        paid: payment?.paid,
        pmtType: payment?.pmtType,
        pmtDate: payment?.pmtDate,
        pmtMode: payment?.pmtMode,
        utr: payment?.utr,
        desc: payment?.desc,
        amount: payment?.amount,
        status: payment?.status,
      })),
    destRecon: item?.destRecon,
  }));

  const handleExport = () => {
    const headers = [
      "Trade Id",
      "Source Trade Name",
      "Destination Trade Name",
      "Trade Type",
      "Trade Status",
      "Final Payable",
      "Final Receivable",
      "Trade Recon Status",
      "Source Pay ID",
      "Source Paid",
      "Source Payment Type",
      "Source Payment Date",
      "Source Payment Mode",
      "Source UTR",
      "Source Description",
      "Source Amount",
      "Source Status",
      "Source Recon Status",
      "Destination Pay ID",
      "Destination Paid",
      "Destination Payment Type",
      "Destination Payment Date",
      "Destination Payment Mode",
      "Destination UTR",
      "Destination Description",
      "Destination Amount",
      "Destination Status",
      "Destination Recon Status",
    ];

    const exportData: any[] = [headers];

    data.forEach((row) => {
      const maxLength = Math.max(
        row.tradePmtsOutward?.length || 0,
        row.tradePmtsInward?.length || 0
      );

      for (let i = 0; i < maxLength; i++) {
        exportData.push([
          i === 0 ? row.tradeId : "", // Only show tradeId for the first row
          i === 0 ? row.srcTradeName : "",
          i === 0 ? row.destTradeName : "",
          i === 0 ? row.tradeType : "",
          i === 0 ? row.tradeStatus : "",
          i === 0 ? row.netPayable : "",
          i === 0 ? row.netReceivable : "",
          i === 0 ? row.tradeRecon : "",
          row.tradePmtsOutward?.[i]?.payoutId || "",
          row.tradePmtsOutward?.[i]?.paid || "",
          row.tradePmtsOutward?.[i]?.pmtType || "",
          row.tradePmtsOutward?.[i]?.pmtDate || "",
          row.tradePmtsOutward?.[i]?.pmtMode || "",
          row.tradePmtsOutward?.[i]?.utr || "",
          row.tradePmtsOutward?.[i]?.desc || "",
          row.tradePmtsOutward?.[i]?.amount || "",
          row.tradePmtsOutward?.[i]?.status || "",
          i === 0 ? row.srcRecon : "",
          row.tradePmtsInward?.[i]?.payoutId || "",
          row.tradePmtsInward?.[i]?.paid || "",
          row.tradePmtsInward?.[i]?.pmtType || "",
          row.tradePmtsInward?.[i]?.pmtDate || "",
          row.tradePmtsInward?.[i]?.pmtMode || "",
          row.tradePmtsInward?.[i]?.utr || "",
          row.tradePmtsInward?.[i]?.desc || "",
          row.tradePmtsInward?.[i]?.amount || "",
          row.tradePmtsInward?.[i]?.status || "",
          i === 0 ? row.destRecon : "",
        ]);
      }
    });

    if (exportData.length > 1) {
      const worksheet = XLSX.utils.aoa_to_sheet(exportData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Reports");
      XLSX.writeFile(workbook, `Open Trade Summary report.xlsx`);
    } else {
      toast.error("No data available to export excel.");
    }
  };

  // Call filterTraders whenever searchText or selectedStatus in Redux changes
  useEffect(() => {
    filterTraders();
  }, [searchText, allExcessPaymentReport]);

  return (
    <>
      <div className="flex justify-between pb-3">
        <div className="relative w-full focus-within:border-primary" style={{ width: '30%', border: '1px solid #ccc', borderRadius: '4px' }}>
          <FaSearch
            className="absolute color-primary"
            size={15}
            style={{ top: "14px", left: "6px" }}
          />
          <Input
            type="text"
            palceholder="Search by Trade Id"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            inputStyle={{ textIndent: "22px", padding: "8px 5px" }}
          />
        </div>
        <div
          onClick={() => {
            handleExport();
          }}
          className="text-sm cursor-pointer flex items-center justify-center br-10 gap-2 bg-white"
          style={{
            padding: "8px 5px",
          }}
        >
          <img src={excel} alt="img" className="" style={{ width: "25px" }} />{" "}
          <span>Export to Excel</span>
        </div>
      </div>
     
    </>
  );
};
export default ExcessPaymentReport;
