import React, { FC, useEffect, useState } from "react";
import { FaAngleDown, FaChevronRight } from "react-icons/fa6";
import EmptyProfile from "../../assets/images/profile.jpg";
import { useNavigate } from "react-router-dom";
import useAllTraders from "../../hooks/useAllTraders";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedStatus, updateTraderId } from "../../reducers/tradeSlice";
import {
  getCurrencyNumberWithOutSymbol,
  getMaskedMobileNumber,
} from "../../helpers/get-currency-number";
import Popover from "../../components/ui/popover";
import { FaSearch } from "react-icons/fa";
import { Input } from "../../components/ui";
import useCustomerDocs from "../../hooks/useCustomerDocs";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Checkbox } from "@mui/material";
import { TRootState } from "../../store";
import NodataAvailable from "../../assets/images/NodataAvailable.svg";
import exclamatory from "../../assets/images/exclamatory.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import approveTick from "../../assets/images/approveTick.svg";

const AllCustomers: FC<{}> = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);

  // const [searchText, setSearchText] = useState("");
  const { traders, getAllTraders, onRefresh, setOnRefresh, role } =
    useAllTraders();

  const label = { inputProps: { "aria-label": "Checkbox demo" } }; //checkbox kosam
  const { traderStatus, getStatusDetails } = useCustomerDocs({});

  const { selectedStatus, allTraders } = useSelector((state: TRootState) => ({
    selectedStatus: state.trades.selectedStatus,
    allTraders: state.trades.allTraders,
  }));

  const [searchText, setSearchText] = useState("");
  const [filteredTraders, setFilteredTraders] = useState<any[]>([]);
  const [open, setOpen] = useState(false);

  // Local state to hold selected statuses temporarily
  const [localSelectedStatus, setLocalSelectedStatus] = useState<string[]>([]);

  useEffect(() => {
    if (localSelectedStatus.length > 0) {
      const updatedFilterdStatus = localSelectedStatus.filter((item) =>
        selectedStatus.includes(item)
      );
      setLocalSelectedStatus(updatedFilterdStatus);
    }
  }, [open]);
  useEffect(() => {
    // Initialize localSelectedStatus with selectedStatus from Redux on component mount or update
    setLocalSelectedStatus(selectedStatus);
  }, [selectedStatus]);

  const filterTraders = () => {
    let filtered = allTraders;
    const trimmedSearchText = searchText.trim().toLowerCase();
    // Apply search filter
    if (searchText.length > 0) {
      filtered = filtered.filter(
        (option) =>
          (option.tradeType &&
            option.tradeType.toLowerCase().includes(trimmedSearchText)) ||
          (option.traderId &&
            option.traderId
              .toString()
              .toLowerCase()
              .includes(trimmedSearchText)) ||
          (option.tradeName &&
            option.tradeName.toLowerCase().includes(trimmedSearchText)) ||
          (option.marketName &&
            option.marketName.toLowerCase().includes(trimmedSearchText))
      );
    }

    // Apply status filter from Redux selectedStatus
    if (selectedStatus.length > 0) {
      filtered = filtered.filter((option) =>
        selectedStatus.includes(option.status) 
      );
    }

    setFilteredTraders(filtered);
    setOpen(false);
  };

  // Call filterTraders whenever searchText or selectedStatus in Redux changes
  useEffect(() => {
    filterTraders();
  }, [searchText, selectedStatus, allTraders]);

  // Handle checkbox changes for localSelectedStatus without dispatching to Redux
  const handleCheckboxChange = (status: string) => {
    setLocalSelectedStatus((prev) =>
      prev.includes(status)
        ? prev.filter((s) => s !== status)
        : [...prev, status]
    );
  };

  const handleApply = () => {
    dispatch(setSelectedStatus(localSelectedStatus)); // Update Redux state with local selected statuses
    setOpen(false);
  };

  const handleClearAll = () => {
    setSearchText(""); // Clear search text if desired
    setFilteredTraders([]);
    setLocalSelectedStatus([]); // Clear local selected statuses
    dispatch(setSelectedStatus([])); // Clear Redux state
    setOpen(false);
  };

  useEffect(() => {
    if (traders.length == 0) {
      getAllTraders();
    }
    getStatusDetails();
  }, [traders]);

  type TraderStatus =
    | "UNDER REVIEW"
    | "ACTIVE"
    | "BLOCKED"
    | "HOLD"
    | "REJECTED";

  const statusColors: Record<TraderStatus, string> = {
    "UNDER REVIEW": "var(--ono-orange)",
    ACTIVE: "var(--ono-primary)",
    BLOCKED: "var(--ono-red)",
    HOLD: "var(--ono-orange)",
    REJECTED: "var(--ono-red)",
  };
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const tableStyle: React.CSSProperties = {
    width: '100%',
    borderCollapse: 'collapse',
    borderRadius: '10px',
    overflow: 'hidden',
    border: '1px solid #888', 
  };

  return (
    <div className="ml-3 mr-3">
      <div className="flex justify-between px-0 md:px-4 py-4">
        <div className="flex relative">
          <FaSearch
            className="absolute color-primary"
            size={15}
            style={{ top: "14px", left: "6px" }}
          />
          <Input
            type="text"
            palceholder="Search by Trader Name / Trader ID / Type / Market Name"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            style={{ width: isMobile ? "auto" : "450px" }}
            inputStyle={{ textIndent: "22px", padding: "8px 5px" }}
          />
        </div>

        <div className=" flex gap-2 mr-2 md:mr-0">
          <div className="">
            <button
              onClick={() => {
                setOpen(true);
              }}
              className="flex items-center bg-white border border-gray color-primary rounded-lg px-2 py-2 focus:outline-none focus:ring-2 focus:ring-green-200"
            >
              <FilterAltIcon className="w-4 h-4 mr-2" />
              <span className="hidden md:flex">Filter By:{"  "}</span>
              <span className="text-gray-700 px-1 hidden md:flex">
                Status({selectedStatus.length})
              </span>
              <span className="text-gray-700 px-4 hidden md:flex">
                <ArrowDropDownIcon />
              </span>
            </button>

            {open && (
              <div className="mr-0 md:mr-12">
                <Popover
                  open={open}
                  onClose={() => {
                    setOpen(false);
                  }}
                  className="w-[230px] h-auto"
                  style={{
                    marginLeft: window.innerWidth >= 768 ? "7.2rem" : undefined,
                    marginRight: window.innerWidth < 768 ? "1rem" : undefined,
                  }}
                >
                  <div>
                    {traderStatus.map((status) => (
                      <div
                        className="p-1 cursor-pointer"
                        onClick={() => handleCheckboxChange(status.status)}
                      >
                        <Checkbox
                          {...label}
                          checked={localSelectedStatus.includes(status.status)}
                          // onChange={() => handleCheckboxChange(status.status)}
                        />{" "}
                        {status.status}
                      </div>
                    ))}

                    <div className="flex p-3 gap-2" style={{ width: "220px" }}>
                      <button
                        className=" align-items-center rounded-md h-[40px]"
                        style={{ backgroundColor: "#d7f3dd", width: "100px" }}
                        onClick={() => {
                          handleClearAll();
                        }}
                      >
                        <span className="color-primary"> Clear All</span>
                      </button>
                      <button
                        className="bg-primary align-items-center rounded-md h-[40px]"
                        style={{ backgroundColor: "white", width: "100px" }}
                        onClick={() => {
                          handleApply();
                          filterTraders();
                        }}
                      >
                        <span className="text-white">Apply</span>
                      </button>
                    </div>
                  </div>
                </Popover>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="bg-gray"
        style={{
          height: isMobile ? "calc(100vh - 13rem)" : "calc(100vh - 12rem)",
          // width: "500px",
          overflowX: "auto",
        }}
      >
        <div className="md:ml-3 md:mr-3">
          <div
            className="overflow-y-auto overflow-x-auto"
            style={{ height: "calc(100vh - 12rem)" }}
          >
            <table className="text-sm table-auto border-collapse" style={tableStyle}>
              <thead className="sticky top-0 bg-gray-50 z-10">
                <tr className="bg-light-primary br-10">
                  <th
                    className="px-2 md:px-4 py-2 border text-center w-[2%]"
                    style={{
                      width: isMobile ? "50px" : "",
                      minWidth: isMobile ? "50px" : "",
                      position: isMobile ? "sticky" : "relative",
                      top: 0,
                      left: 0,
                      zIndex: 999,
                      backgroundColor: "#d7f3dd",
                    }}
                  >
                    #
                  </th>
                  <th
                    // className="px-2 py-2 font-normal"
                    // style={{ minWidth: isMobile ? "250px" : "" }}
                    className="px-2 md:px-4 py-2 border "
                    style={{
                      width: isMobile ? "150px" : "",
                      minWidth: isMobile ? "160px" : "",
                      position: isMobile ? "sticky" : "relative",
                      top: isMobile ? 0 : 0,
                      left: isMobile ? 50 : 0,
                      zIndex: 999,
                      backgroundColor: "#d7f3dd",
                    }}
                  >
                    Trader
                  </th>
                  <th className="px-2 py-2 font-normal">PDC</th>
                  <th
                    className="px-2 py-2 font-normal"
                    style={{ minWidth: isMobile ? "100px" : "" }}
                  >
                    Trader Type
                  </th>
                  <th
                    className="px-4 py-2 font-normal"
                    style={{ minWidth: isMobile ? "200px" : "" }}
                  >
                    Market
                  </th>
                  <th
                    className="px-2 py-2 font-normal"
                    style={{ minWidth: isMobile ? "100px" : "" }}
                  >
                    Pending Balance(₹)
                  </th>
                  <th
                    className="px-3 py-2 font-normal"
                    style={{ minWidth: isMobile ? "100px" : "" }}
                  >
                    Trade Limit(₹)
                  </th>
                  <th
                    className="px-2 py-2 font-normal"
                    style={{ minWidth: isMobile ? "150px" : "" }}
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {filteredTraders.length > 0 ? (
                  filteredTraders.map((trader, index) => (
                    <tr
                      key={trader.traderId}
                      onClick={() => {
                        navigate(`/allDetails/${trader.traderId}`);
                        dispatch(
                          updateTraderId({
                            traderId: trader.traderId,
                            tradeStatus: "ALL",
                          })
                        );
                      }}
                      className="cursor-pointer"
                    >
                      <td
                        // className="font-semibold text-center"
                        className="px-2 md:px-4 py-2 text-center border"
                        style={{
                          // width: isMobile ? "50px" : "",
                          // minWidth: isMobile ? "50px" : "",
                          position: isMobile ? "sticky" : "relative",
                          top: isMobile ? 0 : 0,
                          left: isMobile ? 0 : 0,
                          zIndex: 2,
                          backgroundColor: "#fff",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        // className="px-2"
                        className="px-2 md:px-4 py-2 border"
                        style={{
                          position: isMobile ? "sticky" : "relative",
                          top: isMobile ? 0 : 0,
                          left: isMobile ? 50 : 0,
                          zIndex: 2,
                          backgroundColor: "#fff",
                        }}
                      >
                        <div className="flex items-center space-x-2 md:space-x-4">
                          <img
                            src={
                              trader?.profilePic
                                ? trader.profilePic
                                : EmptyProfile
                            }
                            alt=""
                            className="w-10 h-10 rounded-full"
                          />
                          <div className="flex flex-col">
                            <div className="font-semibold">
                              {trader.tradeName}
                            </div>
                            <div>
                              Trader-
                              <span className="color-blue">
                                {trader.traderId}
                              </span>{" "}
                              | {getMaskedMobileNumber(trader.mobile || "")}
                            </div>
                            <div>{trader.name}</div>
                          </div>
                        </div>
                      </td>
                      <td className="px-2 md:px-4 py-2 border text-sm">
                        <div className="flex justify-center items-center">
                          {trader.pdcStatus ? (
                            <img src={approveTick} className="w-5 h-5" />
                          ) : (
                            <img src={exclamatory} className="w-5 h-5" />
                          )}
                        </div>
                      </td>
                      <td className="w-[8%] px-2 md:px-4 py-2 border text-sm">
                        {trader.tradeType ?? "-"}
                      </td>
                      <td className="px-2 md:px-4 py-2 border text-sm">
                        <div className="flex items-center font-semibold space-x-2">
                          {trader.marketName ?? "-"}
                        </div>
                      </td>
                      <td className="px-2 md:px-4 py-2 border text-sm">
                        {" "}
                        <span
                          className={`text-sm font-semibold ${
                            trader.tradeType === "SOURCE"
                              ? "color-orange"
                              : "color-primary"
                          }`}
                        >
                          {getCurrencyNumberWithOutSymbol(
                            trader.amtDetails?.paidReceived -
                              trader.amtDetails?.paidAmount || 0
                          )}
                        </span>
                        <br />
                        <span className="color-black text-sm">
                          {trader.tradeType === "SOURCE"
                            ? "To Be Paid"
                            : "To Be Received"}
                        </span>
                      </td>
                      <td className="px-2 md:px-4 py-2 border text-sm">
                        <span className="font-semibold">
                          {getCurrencyNumberWithOutSymbol(
                            trader.tradeLimit || 0
                          )}
                        </span>
                        {trader.amtDetails && (
                          <p className="text-sm">Avl Limit: --</p>
                        )}
                      </td>
                      <td className="">
                        <div className="flex">
                          <div
                            style={{ height: "45px", width: "45px" }}
                            className="py-1 px-1"
                          >
                            <CircularProgressbar
                              value={
                                trader?.traderProgress
                                  ? Number(
                                      trader.traderProgress.replace("%", "")
                                    )
                                  : 0
                              }
                              text={`${
                                trader?.traderProgress
                                  ? trader.traderProgress
                                  : "0%"
                              }`}
                              styles={{
                                path: {
                                  stroke: trader?.status
                                    ? statusColors[
                                        trader.status as TraderStatus
                                      ]
                                    : "var(--ono-gray)",
                                },
                                text: {
                                  fill: trader?.status
                                    ? statusColors[
                                        trader.status as TraderStatus
                                      ]
                                    : "var(--ono-gray)",
                                  fontSize: "25px",
                                },
                              }}
                              strokeWidth={13}
                            />
                          </div>

                          <div
                            className={`flex items-center space-x-2 font-semibold mr-2 ml-2 ${
                              trader.status === "ACTIVE"
                                ? "color-primary"
                                : trader.status === "UNDER REVIEW" ||
                                  trader.status === "HOLD"
                                ? "color-orange"
                                : trader.status === "BLOCKED" ||
                                  trader.status === "REJECTED"
                                ? "color-red"
                                : ""
                            }`}
                          >
                            <span>{trader.status}</span>
                          </div>

                          <div className="ml-auto py-4">
                            <FaChevronRight />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="py-8">
                      <div className="flex justify-center items-center">
                        <img src={NodataAvailable} alt="No Data" />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllCustomers;
