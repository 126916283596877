import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../constants";
import { handelLogout } from "../reducers/authSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getCurrentDateTime } from "../helpers/datetime";
const useAxiosClient = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  /** Setting common axios header  */
  const traderId = "1";
  const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
  });
  /** Setting common axios header  */
  const clientID = localStorage.getItem("clientId") || "";
  const clientSecret = localStorage.getItem("clientSecret") || "";

  axiosInstance.defaults.headers.common["Content-Type"] = "application/json";
  axiosInstance.defaults.headers.common["clientId"] = clientID;
  axiosInstance.defaults.headers.common["clientSecret"] = clientSecret;
  const navigate = useNavigate();
  /** Function to attach click id in URL */
  const handleCreateURL = (_url = "") => {
    let newURL = _url;
    if (_url.includes(":traderId")) {
      newURL = _url.replace(":traderId", traderId);
      return newURL;
    }

    if (_url.includes(":traderId")) {
      newURL = _url.replace(":traderId", traderId);
    }

    return newURL;
  };
  const handleLogoutFun = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    localStorage.clear();
    dispatch(handelLogout(null));
    navigate("/");
    window.location.reload();
    //@ts-ignore
    // oNoLocalStorage.set("LinkPath", "/smartboard", false);
    // //@ts-ignore
    // oNoLocalStorage.set("statusPlan", "FAILURE", false);
    // //@ts-ignore
    // oNoLocalStorage.set("LinkId", "1", false);
    //@ts-ignore
    oNoLocalStorage.set("isauth", false, false);
  };
  const handleGetCall = <T>(url: string): Promise<{ data: T }> => {
    return new Promise((resolve, reject) => {
      const apiURL = handleCreateURL(url);
      axiosInstance
        .get(`${apiURL}`)
        .then(({ data }) => {
          resolve({ data: data.data });
        })
        .catch((error: any) => {
          console.log(error, "error get");
          // console.log(error.message.includes("Network Error"), error.message);
          if (error.message && error.message.includes("Network Error")) {
            toast.error("Network Error: Unable to reach the server.");
          } else if (
            error.response.data &&
            error.response.data.status.message === "INVALID CREDENTIALS"
          ) {
            handleLogoutFun();
          } else if (
            error.status == 400 ||
            error.status == 500 ||
            error.status == 502 ||
            error.status == 503
          ) {
            if (error.response.status === 502) {
              console.log(`[${apiURL}  ${getCurrentDateTime()}] 502 Bad Gateway Error:`, error.response.data);
             } if (
              error.response.data.status === 404
            ) {
              toast.error(error.response.data.error);
            } else {
              toast.error(error.response.data.status.description);
            }
          }
          reject(error);
        });
    });
  };
  const handlePostCall = <T>({
    URL = "",
    apiParams = {},
    config = {},
  }): Promise<{ data: T; status: any }> => {
    return new Promise((resolve, reject) => {
      const apiURL = handleCreateURL(URL);
      axiosInstance
        .post(`${apiURL}`, apiParams, config)
        .then(({ data }) => {
          resolve({ data: data.data, status: data.status });
        })
        .catch((error: any) => {
          console.log(error, "error post", error.status);
          if (error.message && error.message.includes("Network Error")) {
            toast.error("Network Error: Unable to reach the server.");
          } else if (error.response.data.status.message === "INVALID CREDENTIALS") {
              handleLogoutFun();
            } else if (
              error.status == 400 ||
              error.status == 500 ||
              error.status == 502 ||
              error.status == 503 ||
              error.status == 404
            ) {
              if (error.response.status === 502) {
                console.log(`[${apiURL}  ${getCurrentDateTime()}] 502 Bad Gateway Error:`, error.response.data);
               }
              if (error.status == 404) {
                console.log("404");
                toast.error(error.response.data.error);
              } else {
                toast.error(error.response.data.status.description);
              }
            }
          reject(error);
        });
    });
  };
  const handlePutCall = <T>({
    URL = "",
    apiParams = {},
  }): Promise<{ data: T; status: any }> => {
    return new Promise((resolve, reject) => {
      const apiURL = handleCreateURL(URL);
      axiosInstance
        .put(`${apiURL}`, apiParams)
        .then(({ data }) => {
          resolve({ data: data.data, status: data.status });
        })
        .catch((error: any) => {
          console.log(error, "put");
          if (error.response.status === 502) {
            console.log(`[${apiURL}  ${getCurrentDateTime()}] 502 Bad Gateway Error:`, error.response.data);
           }
          if (error.message && error.message.includes("Network Error")) {
            toast.error("Network Error: Unable to reach the server.");
          } else if (error.response.data.status.message === "INVALID CREDENTIALS") {
              handleLogoutFun();
            } else if (
              error.status == 400 ||
              error.status == 500 ||
              error.status == 502 ||
              error.status == 503
            ) {
              toast.error(error.response.data.status.description);
            }
          reject(error);
        });
    });
  };
  return {
    handlePutCall,
    handleGetCall,
    handlePostCall,
  };
};

export default useAxiosClient;
