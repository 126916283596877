import React, { FC, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import OpenTrades from "./OpenTrades";
import CloseTrades from "./CloseTrades";
import Ledgers from "./Ledgers";
import TradeDetails from "./TradeDetails";
import useTraderById from "../../../hooks/useTraderById";
import { useDispatch } from "react-redux";
import { updateTraderId } from "../../../reducers/tradeSlice";
import { TTraderId } from "../../../types";
import useCustomerDocs from "../../../hooks/useCustomerDocs";
import TradeStatus from "../../trade-detail/trade-status";
import CircularProgress from "@mui/material/CircularProgress";

const AllDetails: React.FC<{}> = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [refresh, setRefresh] = useState(false); // for refreshing the screen
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Or use any breakpoint you prefer
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const { traderView, getTraderDetails, activeTab, setActiveTab, loading } =
    useCustomerDocs({
      traderId: parseInt(params.traderId || "0"),
      fromMobile: isMobile,
    });

  useEffect(() => {
    getTraderDetails();
  }, [refresh, activeTab, params]);

  return (
    <div>
      <div className="border-b">
        <div className="block md:flex overflow-y-auto">
          <div className="w-[100%] md:w-[40%] mt-2 flex items-center">
            <button
              onClick={() => {
                navigate("/customers");
              }}
            >
              <span className="ml-4">
                <ArrowBackIcon />{" "}
              </span>{" "}
            </button>{" "}
            <span className="font-semibold ml-2">
              {" "}
              {traderView?.tradeName} - {traderView?.traderId}
            </span>
          </div>

          <div className="flex w-[60%]">
            <ul className="flex flex-row space-x-4">
              {isMobile && (
                <button
                  className={`cursor-pointer whitespace-nowrap px-4 py-2 rounded-t-lg bg-white-200 
              ${
                activeTab === "Trader Details"
                  ? "color-primary border-green-600 border-b-4"
                  : ""
              }`}
                  onClick={() => {
                    setActiveTab("Trader Details");
                    // dispatch(
                    //   updateTraderId({
                    //     traderId: traderView?.traderId ?? 0,
                    //     tradeStatus: "ALL",
                    //   })
                    // );
                  }}
                >
                  Trader Details
                </button>
              )}
              <button
                className={`cursor-pointer whitespace-nowrap px-4 py-2 rounded-t-lg bg-white-200 
              ${
                activeTab === "All Trades"
                  ? "color-primary border-green-600 border-b-4"
                  : ""
              }`}
                onClick={() => {
                  setActiveTab("All Trades");
                  dispatch(
                    updateTraderId({
                      traderId: traderView?.traderId ?? 0,
                      tradeStatus: "ALL",
                    })
                  );
                }}
              >
                All Trades
                {traderView?.trades !== null && activeTab === "All Trades"
                  ? `(${traderView?.trades.length})`
                  : ""}
              </button>

              <button
                className={`cursor-pointer whitespace-nowrap px-4 py-2 rounded-t-lg bg-white-200 
              ${
                activeTab === "Open Trades"
                  ? "color-primary border-green-600 border-b-4"
                  : ""
              }`}
                onClick={() => {
                  setActiveTab("Open Trades");
                  dispatch(
                    updateTraderId({
                      traderId: traderView?.traderId ?? 0,
                      tradeStatus: "OPEN",
                    })
                  );
                }}
              >
                Open Trades
                {traderView?.trades !== null && activeTab === "Open Trades"
                  ? `(${traderView?.trades.length})`
                  : ""}
              </button>

              <button
                className={`cursor-pointer whitespace-nowrap px-4 py-2 rounded-t-lg bg-white-200 
              ${
                activeTab === "Close Trades"
                  ? "color-primary border-green-600 border-b-4"
                  : ""
              }`}
                onClick={() => {
                  setActiveTab("Close Trades");
                  dispatch(
                    updateTraderId({
                      traderId: traderView?.traderId ?? 0,
                      tradeStatus: "CLOSED",
                    })
                  );
                }}
              >
                Close Trades
                {traderView?.trades !== null && activeTab === "Close Trades"
                  ? `(${traderView?.trades.length})`
                  : ""}
              </button>

              <button
                className={`cursor-pointer px-4 py-2 rounded-t-lg bg-white-200 
              ${
                activeTab === "Ledger"
                  ? "color-primary border-green-600 border-b-4"
                  : ""
              }`}
                onClick={() => {
                  setActiveTab("Ledger");
                }}
              >
                Ledger
              </button>
            </ul>
          </div>
        </div>
      </div>

      {/* we are usig single Ui and changing all, open, closed trades*/}

      <TradeDetails
        trader={traderView}
        onSubmit={() => {
          setRefresh(!refresh);
        }}
        loading={loading}
        activeTab={activeTab}
        progressLimit={traderView?.traderProgress || 0}
      />

      {/* <div>{
        activeTab === "All Trades" && 
        <TradeDetails />
        }
        </div> */}

      {/* <div>
        {
        activeTab === "Open Trades" && (
          <OpenTrades />
        )}
      </div>

      <div>{activeTab === "Close Trades" && <CloseTrades />}</div> */}

      {/* <div>{activeTab === "Ledgers" && <Ledgers />}</div> */}
    </div>
  );
};
export default AllDetails;
